/* eslint-disable @typescript-eslint/promise-function-async */
import type { CustomRoute } from "@/types";
import { sift } from "radash";
import { lazy } from "react";

// ROUTE IMPORTS
import { actsListRoutes } from "@/features/acts-list";
import { authRoutesWith } from "@/features/auth";
import dashboarRoutes from "@/features/dashboard/routes";
import { inspectorsRoutes } from "@/features/inspectors";
import { notificationRoutes } from "@/features/notification";
import { violationRoutes } from "@/features/offenses";
import reportsRoutes from "@/features/reports/routes";
import { formsRoutes } from "@/features/tablet-forms";
import PdfGen from "@/views/pdf-gen";
import ProfileDelete from "@/views/profile-delete";
import SupportPage from "@/views/support";

// Global Pages
const Root = lazy(() => import("@/views/root"));
const NotFound = lazy(() => import("@/views/not-found"));
const Error = lazy(() => import("@/views/error"));
const currentPosition = JSON.parse(
  localStorage.getItem("user") || "{}",
).position;

const allPositions = [
  "inspector",
  "superadmin",
  "executive",
  "accountant",
  "executive-region",
];

const childrensWithPositionCheck = () => {
  switch (currentPosition) {
    case "superadmin":
      return sift([
        dashboarRoutes,
        // statsRoutes,
        actsListRoutes,
        notificationRoutes,
        inspectorsRoutes,
        violationRoutes,
        formsRoutes,
        reportsRoutes,
      ]);
    case "accountant":
      return sift([dashboarRoutes, violationRoutes, reportsRoutes]);
    case "executive":
      return sift([
        dashboarRoutes,
        // statsRoutes,
        actsListRoutes,
        notificationRoutes,
        inspectorsRoutes,
        violationRoutes,
        formsRoutes,
        reportsRoutes,
      ]);
    case "executive-region":
      return sift([
        dashboarRoutes,
        actsListRoutes,
        notificationRoutes,
        violationRoutes,
        formsRoutes,
      ]);
    case "inspector":
      return sift([dashboarRoutes, actsListRoutes]);
    default:
      return sift([]);
  }
};

const routes: CustomRoute[] = [
  {
    id: "root",
    title: "E-Dalolatnoma",
    path: "/",
    element: <Root getRoutes={() => routes} />,
    errorElement: <Error getRoutes={() => routes} />,
    children: childrensWithPositionCheck(),
  },
  authRoutesWith(() => routes),
  {
    id: "pdf-gen",
    title: "pdf-gen",
    path: "pdf-gen",
    element: <PdfGen />,
  },
  {
    id: "profile-delete",
    title: "profile-delete",
    path: "profile_delete",
    element: <ProfileDelete />,
  },
  {
    id: "global-not-found",
    title: "not-found",
    path: "*",
    element: <NotFound getRoutes={() => routes} />,
  },
  {
    id: "support",
    title: "support",
    path: "support",
    element: <SupportPage />,
  }
];

export default routes;
